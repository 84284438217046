import { Dimensions, StyleSheet } from "react-native";

const screenHeight = Dimensions.get('window').height;

export const globalStyles = StyleSheet.create({
    globalContainer: {
      height: "100%",
      maxHeight: screenHeight,
      backgroundColor: "white",
      paddingBottom: 50
    },
    screenContainerMobile: {
      flex: 1,
      backgroundColor: "white",
      padding: 20
    },
    screenContainerDesktop: {
      flex: 1,
      backgroundColor: "white",
      padding: 20,
      width: 670,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
});