import React, { Component } from "react";
import {
 Image,
 Text,
 View,
 StyleSheet,
 SafeAreaView,
 Picker,
 ScrollView,
 TouchableOpacity,
 Alert,
 StatusBar,
 Platform,
} from "react-native";
import { Button, Dialog, TextInput } from "react-native-paper";

import {
 login,
 getEspecialidadesWA,
 getEstados,
 postUsuariosAdmin,
 register,
 getUserData,
 putAlumnos,
} from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import { KeyboardAvoidingView } from "react-native";
import RNPickerSelect from "react-native-picker-select";
import * as Font from "expo-font";
import { globalStyles } from "../Layout";

class Profile extends Component {
 state = {
  nombre: "",
  email: "",
  password: "",
  c_password: "",
  celular: "",
  estado: "",
  ciudad: "",
  universidad: "",
  especialidad: "",
  intentos_enarm: "",
  especialidades: [],
  estados: [],
  error: "",
  nombreError: "",
  emailError: "",
  passwordError: "",
  c_passwordError: "",
  celularError: "",
  estadoError: "",
  ciudadError: "",
  universidadError: "",
  especialidadError: "",
  intentosError: "",
 };

 componentDidMount() {
  getEspecialidadesWA().then((res) => {
   this.setState({
    especialidades: res,
   });
  });
  getEstados().then((res) => {
   this.setState({
    estados: res,
   });
  });
  Font.loadAsync({
   "cerebri-sans-semibold": require("../../assets/fonts/CerebriSans-SemiBold.ttf"),
  });

  Font.loadAsync({
   "cerebri-sans-regular": require("../../assets/fonts/CerebriSans-Regular.ttf"),
  });
  if (Platform.OS === 'web') {
    const token = localStorage.getItem('user_token');
    const user_name = localStorage.getItem('user_name')

    this.setState({
      token,
      user_name
    });
  } else {
    AsyncStorage.getItem("user_token", (error, result) => {
      this.setState({
       token: result,
      });
     });
     AsyncStorage.getItem("user_id", (error, result) => {
      this.setState(
       {
        user_id: result,
       },
       () => {
        this.getUser()
       }
      );
     });
  }
 }

 getUser = () => {
  const data = {
    token: this.state.token,
    user_id: this.state.user_id,
   };
   getUserData(data).then((res) => {
    if (!res) {
     Alert.alert("", "error");
    } else {
     this.setState({
      nombre: res.name,
      celular: res.numero,
      estado: parseInt(res.estado_id),
      ciudad: res.ciudad,
      especialidad: parseInt(res.especialidad_id),
     });
    }
   });
 }

 validate_fields = () => {
  let nombreError = "";
  let emailError = "";
  let passwordError = "";
  let c_passwordError = "";
  let celularError = "";
  let estadoError = "";
  let ciudadError = "";
  let universidadError = "";
  let especialidadError = "";
  let intentosError = "";

  if (this.state.nombre == "") {
   nombreError = "Nombre no puede ser vacio";
  }

  /* if (this.state.email == "") {
      emailError = "Correo no puede ser vacio";
    }
     if (this.state.intentos_enarm == "") {
      intentosError = "Intentos no puede ser vacio";
    }
    if (this.state.password == "") {
      passwordError = "Contrase;a no puede ser vacia";
    }
    if (this.state.c_password !== this.state.password) {
      c_passwordError = "Las contrase;as no coinciden";
    }
    if (this.state.universidad == "") {
      universidadError = "Universidad no puede ser vacia";
    } 
    if (this.state.celular == "") {
      celularError = "Celular no puede ser vacio";
    */
  if (this.state.estado == "") {
   estadoError = "Selecciona un estado";
  }
  if (this.state.ciudad == "") {
   ciudadError = "Ciudad no puede ser vacia";
  }
  if (this.state.especialidad == "") {
   especialidadError = "Selecciona una especialidad";
  }

  if (
   nombreError ||
   emailError ||
   passwordError ||
   c_passwordError ||
   celularError ||
   estadoError ||
   ciudadError ||
   universidadError ||
   especialidadError ||
   intentosError
  ) {
   this.setState({
    nombreError,
    emailError,
    passwordError,
    c_passwordError,
    celularError,
    estadoError,
    ciudadError,
    universidadError,
    especialidadError,
    intentosError,
   });
   return false;
  } else {
   this.setState({
    nombreError,
    emailError,
    passwordError,
    c_passwordError,
    celularError,
    estadoError,
    ciudadError,
    universidadError,
    especialidadError,
    intentosError,
   });
   return true;
  }
 };

 handleUpdate = () => {
  const newUser = {
   token: this.state.token,
   user_id: this.state.user_id,
   name: this.state.nombre,
   numero: this.state.celular,
   estado_id: parseInt(this.state.estado),
   ciudad: this.state.ciudad,
   especialidad_id: parseInt(this.state.especialidad),
  };
  const isValid = this.validate_fields();
  if (isValid) {
   putAlumnos(newUser).then((res) => {
    if (res) {
      Alert.alert('Información actualizada')
     this.getUser()
    } else {
     this.setState({
      error: true,
     });
    }
   });
  } else {
   console.log("error chato");
  }
 };

 onFocusChange = () => {
  this.setState({ isFocused: true });
 };
 render() {
  return (
  <View
    style={globalStyles.globalContainer}
  >
   <ScrollView
    style={Platform.OS === 'web' ? globalStyles.screenContainerDesktop : globalStyles.screenContainerMobile}
   >
    <StatusBar barStyle="light-content" backgroundColor="#ED1B24" />
    <KeyboardAvoidingView style={styles.container} behavior="padding">
     <TextInput
      label="Nombre Completo"
      placeholder="Nombre Completo"
      value={this.state.nombre}
      autoCapitalize="words"
      onChangeText={(nombre) => this.setState({ nombre })}
      onFocus={this.onFocusChange}
      theme={{
       colors: { primary: "#000", placeholder: "#aaa" },
       fonts: {
        regular: {
         fontFamily: "cerebri-sans-regular",
         fontWeight: "normal",
        },
       },
      }}
      style={styles.inputText}
      underlineColor="#E2E2E2"
     />

     <Text style={{ color: "red" }}>{this.state.nombreError}</Text>
     {/*<TextInput
            label="Correo Electrónico"
            placeholder="Correo Electrónico"
            style={styles.inputText}
            value={this.state.email}
            onChangeText={email => this.setState({ email })}
            theme={{ colors: { primary: "red" } }}
            keyboardType="email-address"
          />
          <Text style={{ color: "red" }}>{this.state.emailError}</Text> 
          <TextInput
            label="Crear Contraseña"
            placeholder="Crear Contraseña"
            style={styles.inputText}
            value={this.state.password}
            onChangeText={password => this.setState({ password })}
            theme={{ colors: { primary: "red" } }}
            secureTextEntry={true}
          />
          <Text style={{ color: "red" }}>{this.state.passwordError}</Text>
          <TextInput
            label="Confirmar Contraseña"
            placeholder="Confirmar Contraseña"
            style={styles.inputText}
            value={this.state.c_password}
            onChangeText={c_password => this.setState({ c_password })}
            theme={{ colors: { primary: "red" } }}
            secureTextEntry={true}
          />
          <Text style={{ color: "red" }}>{this.state.c_passwordError}</Text> 
          <TextInput
            label="Número Celular"
            placeholder="Número Celular"
            style={styles.inputText}
            value={this.state.celular}
            onChangeText={(celular) => this.setState({ celular })}
            theme={{ colors: { primary: "red" } }}
            keyboardType="number-pad"
          />
          <Text style={{ color: "red" }}>{this.state.celularError}</Text> */}

     <View style={styles.doubleInput}>
      <View style={styles.doubleInputItem}>
       <RNPickerSelect
        onValueChange={(value) => this.setState({ estado: value })}
        value={this.state.estado}
        style={{
         ...pickerSelectStyles,
         iconContainer: {
          top: 25,
          right: 12,
         },
         placeholder: {
          fontFamily: "cerebri-sans-regular",
         },
        }}
        placeholder={{
         label: "Estado",
         value: null,
        }}
        placeholderTextColor="#aaa"
        Icon={() => {
         return (
          <View
           style={{
            backgroundColor: "transparent",
            borderTopWidth: 5,
            borderTopColor: "#000",
            borderRightWidth: 5,
            borderRightColor: "transparent",
            borderLeftWidth: 5,
            borderLeftColor: "transparent",
            width: 0,
            height: 0,
           }}
          />
         );
        }}
        items={this.state.estados.map((estado) => {
         return { label: estado.name, value: estado.id };
        })}
       />
       <Text style={{ color: "red" }}>{this.state.estadoError}</Text>
      </View>
      <View style={styles.doubleInputItem}>
       <TextInput
        label="Ciudad"
        placeholder="Ciudad"
        style={styles.inputText}
        value={this.state.ciudad}
        onChangeText={(ciudad) => this.setState({ ciudad })}
        theme={{
         colors: { primary: "#000", placeholder: "#aaa" },
         fonts: {
          regular: {
           fontFamily: "cerebri-sans-regular",
           fontWeight: "normal",
          },
         },
        }}
        underlineColor="#E2E2E2"
       />

       <Text style={{ color: "red" }}>{this.state.ciudadError}</Text>
      </View>
     </View>
     {/* <TextInput
            placeholder="¿Universidad a la que perteneces?"
            style={styles.inputText}
            value={this.state.universidad}
            onChangeText={universidad => this.setState({ universidad })}
            theme={{ colors: { primary: "red" } }}
          />
         <Text style={{ color: "red" }}>{this.state.universidadError}</Text> */}

     <RNPickerSelect
      onValueChange={(value) => this.setState({ especialidad: value })}
      value={this.state.especialidad}
      style={{
       ...pickerSelectStyles,
       iconContainer: {
        top: 25,
        right: 12,
       },
       placeholder: {
        fontFamily: "cerebri-sans-regular",
       },
       fontFamily: "cerebri-sans-regular",
      }}
      placeholder={{
       label: "Especialidad",
       value: null,
      }}
      placeholderTextColor="#aaa"
      Icon={() => {
       return (
        <View
         style={{
          backgroundColor: "transparent",
          borderTopWidth: 5,
          borderTopColor: "#000",
          borderRightWidth: 5,
          borderRightColor: "transparent",
          borderLeftWidth: 5,
          borderLeftColor: "transparent",
          width: 0,
          height: 0,
         }}
        />
       );
      }}
      items={this.state.especialidades.map((especialidad) => {
       return { label: especialidad.name, value: especialidad.id };
      })}
     />
     <Text style={{ color: "red" }}>{this.state.especialidadError}</Text>
     {/* <TextInput
            placeholder="¿Número de intentos ENARM?"
            style={styles.inputText}
            value={this.state.intentos_enarm}
            onChangeText={intentos_enarm => this.setState({ intentos_enarm })}
            theme={{ colors: { primary: "red" } }}
          />
         <Text style={{ color: "red" }}>{this.state.intentosError}</Text> */}
     <TouchableOpacity
      style={styles.buttonAlt}
      onPress={() => this.props.navigation.navigate("PasswordEdit")}
     >
      <Text style={styles.buttonTextAlt}>Cambiar Contraseña</Text>
     </TouchableOpacity>
     <TouchableOpacity style={styles.buttonMain} onPress={this.handleUpdate}>
      <Text style={styles.buttonText}>Guardar Cambios</Text>
     </TouchableOpacity>
    </KeyboardAvoidingView>
   </ScrollView>
  </View>
  );
 }
}

const styles = StyleSheet.create({
 container: {
  flex: 1,
  backgroundColor: "white",
  padding: 20,
  paddingTop: 25,
 },
 inputText: {
  backgroundColor: "white",
  height: 55,
  color: "#000",
  paddingLeft: 0,
  fontFamily: "cerebri-sans-regular",
  fontSize: 14,
  paddingHorizontal: 0,
 },
 inputTextFocused: {
  backgroundColor: "white",
  borderBottomColor: "#ED1B24",
  borderBottomWidth: 1,
  height: 50,
  color: "#000",
  paddingLeft: 0,
 },
 buttonCreate: {
  backgroundColor: "red",
 },
 doubleInput: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
 },
 doubleInputItem: {
  width: "48%",
 },
 buttonMain: {
  backgroundColor: "#ED1B24",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#fff",
  display: "flex",
  fontWeight: "500",
  fontSize: 13,
  height: 46,
  justifyContent: "center",
  marginTop: 20,
  width: "100%",
 },
 buttonText: {
  textAlign: "center",
  color: "white",
  fontWeight: "700",
 },
 headerTitle: {
  fontSize: 18,
  fontWeight: "500",
  color: "#000",
  fontFamily: "cerebri-sans-semibold",
 },
 backButtonIcon: {
  height: 20,
  width: 20,
 },
 topBar: {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: 30,
  marginBottom: 20,
  marginTop: 10,
 },
 startButtonText: {
  color: "#ED1B24",
  fontSize: 13,
  fontWeight: "600",
  textAlign: "right",
 },
 buttonAlt: {
  backgroundColor: "#fff",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#000",
  display: "flex",
  fontWeight: "500",
  fontSize: 13,
  height: 48,
  justifyContent: "center",
  marginTop: 10,
  width: "100%",
 },
 buttonTextAlt: {
  textAlign: "center",
  textDecorationLine: "underline",
  color: "red",
  fontWeight: "700",
 },
});

const pickerSelectStyles = StyleSheet.create({
 inputIOS: {
  backgroundColor: "white",
  borderBottomColor: "#E2E2E2",
  borderBottomWidth: 1,
  height: 50,
  color: "#000",
  paddingLeft: 0, // to ensure the text is never behind the icon
 },
 inputAndroid: {
  backgroundColor: "white",
  borderBottomColor: "#E2E2E2",
  borderBottomWidth: 1,
  height: 50,
  color: "#000",
  paddingLeft: 0, // to ensure the text is never behind the icon
 },
});

export default Profile;
