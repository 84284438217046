import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
 Image,
 Text,
 View,
 StyleSheet,
 SafeAreaView,
 TouchableOpacity,
 Alert,
 StatusBar,
} from "react-native";
import { TextInput, Button } from "react-native-paper";
import {
 getCupones,
 postValidateCupon,
 postSuscripcion,
 getCuponByCodigo,
} from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";
import { upperCase } from "lodash";

class Cupon extends Component {
 state = {
  codigo: "",
  token: "",
  user_id: "",
  cupon: [],
  plan: this.props.navigation.state.params.plan,
  cuponError: "",
 };

 componentDidMount() {
  Font.loadAsync({
   "cerebri-sans-semibold": require("../../assets/fonts/CerebriSans-SemiBold.ttf"),
  });

  Font.loadAsync({
   "cerebri-sans-regular": require("../../assets/fonts/CerebriSans-Regular.ttf"),
  });
  AsyncStorage.getItem("user_token", (error, result) => {
   this.setState(
    {
     token: result,
    },
    () => {
     AsyncStorage.getItem("user_name", (error, result) => {
      this.setState({
       user_name: result,
      });
     });
    }
   );
  });
 }

 cuponSuscription = () => {
  const codigo = this.state.codigo;
  const data = {
   codigo: codigo,
   token: this.state.token,
  };
  getCuponByCodigo(data).then((res) => {
   if (!res) {
    this.setState({
     cuponError: "Codigo invalido.",
    });
   } else {
    this.setState(
     {
      cupon: res,
      cuponError: "",
     },
     () => {
      this.props.navigation.navigate("SuscriptionCupon", {
       cupon: res,
       plan: this.state.plan,
       plan_id: this.state.plan.id,
       planes: this.props.navigation.state.params.planes,
      });
     }
    );
   }
  });
 };
 render() {
  var heightScreen = Dimensions.get("window").height;
  return (
   <>
    <StatusBar barStyle="light-content" backgroundColor="#ED1B24" />
    {heightScreen > 780 ? (
     <View style={styles.topBarExtra}></View>
    ) : (
     <View style={styles.topBarExtraMin}></View>
    )}
    <View style={styles.topBar}>
     <TouchableOpacity
      style={styles.backButton}
      onPress={() => this.props.navigation.navigate("Start")}
     >
      <Image
       style={styles.backButtonIcon}
       source={require("../../assets/back.png")}
      />
     </TouchableOpacity>
     <Text style={styles.headerTitle}>Cupón de descuento</Text>
     <TouchableOpacity style={styles.backButton}>
      <Image
       style={styles.backButtonIcon}
       source={require("../../assets/backno.png")}
      />
     </TouchableOpacity>
    </View>
    <View style={styles.container}>
     <Text style={styles.text}>
      Introduce el código del cupon y obten tu descuento.
     </Text>
     <TextInput
      label="Código"
      placeholder="Código"
      style={styles.inputText}
      value={this.state.codigo}
      onChangeText={(codigo) => this.setState({ codigo })}
      theme={{
        colors: { primary: "#000", placeholder: "#aaa" },
        fonts: {
         regular: {
          fontFamily: "cerebri-sans-regular",
          fontWeight: "normal",
         },
        },
       }}
       underlineColor="#E2E2E2"
       autoCapitalize="characters"
     />
     <Text style={{ color: "red" }}>{this.state.cuponError}</Text>

     <TouchableOpacity
      style={styles.buttonMain}
      onPress={this.cuponSuscription}
     >
      <Text style={styles.buttonText}>Aplicar Código</Text>
     </TouchableOpacity>
    </View>
   </>
  );
 }
}

const styles = StyleSheet.create({
 container: {
  flex: 1,
  backgroundColor: "white",
  padding: 20,
  paddingTop: 20,
 },
 text: {
    marginTop: 15,
    marginBottom: 15,
    fontFamily: "cerebri-sans-regular",
 },
 inputText: {
    backgroundColor: "white",
    height: 55,
    color: "#000",
    paddingLeft: 0,
    fontFamily: "cerebri-sans-regular",
    fontSize: 14,
    paddingHorizontal: 0,
    textTransform: "uppercase",
 },
 buttonMain: {
  backgroundColor: "#FDCD25",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#fff",
  display: "flex",
  fontWeight: "500",
  fontSize: 13,
  height: 46,
  justifyContent: "center",
  marginTop: 30,
  width: "100%",
 },
 buttonText: {
  textAlign: "center",
  color: "black",
  fontWeight: "700",
  fontFamily: "cerebri-sans-semibold",
 },
 headerTitle: {
  fontSize: 18,
  fontWeight: "500",
  color: "#fff",
  fontFamily: "cerebri-sans-semibold",
 },
 backButtonIcon: {
  height: 20,
  width: 20,
 },
 topBar: {
    alignItems: "center",
    backgroundColor: "#ED1B24",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 45,
    paddingLeft: 20,
    paddingRight: 20,
   },
   topBarExtra: {
    backgroundColor: "#ED1B24",
    height: 45,
   },
   topBarExtraMin: {
    backgroundColor: "#ED1B24",
    height: 25,
   },
   startButtonText: {
    color: "#ED1B24",
    fontSize: 13,
    fontWeight: "600",
    textAlign: "right",
   },
   errorText: {
    fontSize: 11,
    color: "#ED1B24",
    fontFamily: "cerebri-sans-regular",
    marginTop: 5,
   },
});

export default Cupon;
