import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
 Image,
 Text,
 TextInput,
 View,
 StyleSheet,
 SafeAreaView,
 TouchableOpacity,
 Alert,
 StatusBar,
} from "react-native";
import { Button } from "react-native-paper";
import { getCupones, getPlanes, postSuscribe } from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";
import { Avatar, List, RadioButton, DefaultTheme } from "react-native-paper";

class Suscription extends Component {
 state = {
  value: "first",
  email: "",
  token: "",
  cupon: [],
  plan: [],
  plan_id: 1,
  planes: [],
  planError: "",
 };

 componentDidMount() {
  Font.loadAsync({
   "cerebri-sans-semibold": require("../../assets/fonts/CerebriSans-SemiBold.ttf"),
  });

  Font.loadAsync({
   "cerebri-sans-regular": require("../../assets/fonts/CerebriSans-Regular.ttf"),
  });
  AsyncStorage.getItem("user_token", (error, result) => {
   this.setState({
    token: result,
   });
   const data = {
    token: result,
   };
   getPlanes(data).then((res) => {
    this.setState({
     planes: res,
     plan: res[0],
    });
   });
  });
 }

 selectPlan = (plan) => {
  this.setState({
   plan_id: plan.id,
   plan: plan,
  });
 };

 validateFields = () => {
  let planError = "";
  if (this.state.plan_id == "") {
   planError = "Selecciona un plan.";
  }
  if (planError) {
   this.setState({
    planError,
   });
   return false;
  } else {
   return true;
  }
 };

 jumpToCupon = () => {
  const isValid = this.validateFields();
  if (isValid) {
   this.props.navigation.navigate("Cupon", {
    plan: this.state.plan,
    planes: this.state.planes,
   });
  } else {
   Alert.alert("", this.state.planError);
  }
 };

 handleSubscribe = () => {
  const data = {
   token: this.state.token,
   plan_id: this.state.plan_id,
   codigo: "",
  };
  const isValid = this.validateFields();
  if (isValid) {
   postSuscribe(data).then((res) => {
    setTimeout(() => {
     this.props.navigation.navigate("PendingPayment");
    }, 2000);
   });
  }
 };

 render() {
  const planes = this.state.planes;
  var heightScreen = Dimensions.get("window").height;
  return (
   <>
    <StatusBar barStyle="light-content" backgroundColor="#ED1B24" />
    {heightScreen > 780 ? (
     <View style={styles.topBarExtra}></View>
    ) : (
     <View style={styles.topBarExtraMin}></View>
    )}
    <View style={styles.topBar}>
     <TouchableOpacity
      style={styles.backButton}
      onPress={() => this.props.navigation.navigate("Start")}
     >
      <Image
       style={styles.backButtonIcon}
       source={require("../../assets/back.png")}
      />
     </TouchableOpacity>
     <Text style={styles.headerTitle}>Selecciona un plan</Text>
     <TouchableOpacity style={styles.backButton}>
      <Image
       style={styles.backButtonIcon}
       source={require("../../assets/backno.png")}
      />
     </TouchableOpacity>
    </View>
    <View style={styles.container}>
     <Text style={styles.text}>
      Selecciona un plan de acceso, si tienes un cupon de descuento ingresalo.
     </Text>
     <View>
      <List.Section style={styles.especialidadesList}>
       {planes.map((plan) => (
        <List.Item
         title={plan.description}
         titleStyle={{ color: "#000", fontSize: 14, marginTop: 5 }}
         description={"$ " + plan.price}
         descriptionStyle={{
          color: "#AAAAAA",
          fontSize: 13,
          marginTop: 5,
         }}
         key={plan.id}
         style={styles.planesListItem}
         value={plan.id}
         right={() => (
          <>
           <Avatar.Text style={styles.planesListPart} size={26} label=" " />
           <RadioButton
            value={plan.id}
            color="#000"
            size={20}
            status={plan.id == this.state.plan_id ? "checked" : "unchecked"}
            theme={{ fontSize: 10 }}
            onPress={() => {
             this.selectPlan(plan);
            }}
           />
          </>
         )}
        />
       ))}
      </List.Section>
      <TouchableOpacity
       style={styles.buttonAlt}
       onPress={() => this.jumpToCupon()}
      >
       <Text style={styles.buttonTextAlt}>Código de Descuento</Text>
      </TouchableOpacity>
     </View>
     <View style={styles.imageView}>
      <Image
       style={styles.oxxoImage}
       source={require("../../assets/oxxo-conekta.png")}
      />
     </View>
     <TouchableOpacity
      style={styles.buttonMain}
      onPress={() => {
       this.handleSubscribe();
      }}
     >
      <Text style={styles.buttonText}>Realizar Pago</Text>
     </TouchableOpacity>
    </View>
   </>
  );
 }
}

const styles = StyleSheet.create({
 container: {
  flex: 1,
  backgroundColor: "white",
  padding: 20,
  paddingTop: 20,
 },
 text: {
  marginTop: 15,
  marginBottom: 15,
  fontFamily: "cerebri-sans-regular",
 },
 inputText: {
  backgroundColor: "white",
  borderBottomColor: "#E2E2E2",
  borderBottomWidth: 1,
  height: 50,
  color: "#000",
  paddingLeft: 0,
 },
 imageView: {
  alignItems: "center",
  marginTop: "auto",
 },
 buttonMain: {
  backgroundColor: "#FDCD25",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#fff",
  display: "flex",
  fontWeight: "500",
  fontSize: 13,
  height: 46,
  justifyContent: "center",
  marginBottom: 30,
  marginTop: 30,
  width: "100%",
 },
 buttonText: {
  textAlign: "center",
  color: "black",
  fontWeight: "700",
  fontFamily: "cerebri-sans-semibold",
 },
 headerTitle: {
  fontSize: 18,
  fontWeight: "500",
  color: "#fff",
  fontFamily: "cerebri-sans-semibold",
 },
 backButtonIcon: {
  height: 20,
  width: 20,
 },
 topBar: {
  alignItems: "center",
  backgroundColor: "#ED1B24",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: 45,
  paddingLeft: 20,
  paddingRight: 20,
 },
 topBarExtra: {
  backgroundColor: "#ED1B24",
  height: 45,
 },
 topBarExtraMin: {
  backgroundColor: "#ED1B24",
  height: 25,
 },
 startButtonText: {
  color: "#ED1B24",
  fontSize: 13,
  fontWeight: "600",
  textAlign: "right",
 },
 especialidadesList: {
  marginTop: 15,
 },
 especialidadesListSpec: {
  backgroundColor: "#F8BC47",
 },
 planesListPart: {
  fontFamily: "cerebri-sans-semibold",
  display: "flex",
  alignItems: "center",
  top: 8,
  right: 4,
  color: "#222",
  backgroundColor: "#fff",
  borderColor: "#fff",
  borderWidth: 1,
  position: "absolute",
 },
 planesListItem: {
  borderColor: "#E2E2E2",
  borderRadius: 5,
  borderWidth: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 82,
  paddingBottom: 10,
  marginBottom: 20,
 },
 buttonAlt: {
  backgroundColor: "#fff",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#000",
  display: "flex",
  fontWeight: "500",
  fontSize: 13,
  height: 48,
  justifyContent: "center",
  marginTop: 25,
  width: "100%",
 },
 buttonText: {
  textAlign: "center",
  color: "#000",
  fontWeight: "700",
 },
 buttonTextAlt: {
  textAlign: "center",
  textDecorationLine: "underline",
  color: "#000",
  fontWeight: "700",
  fontFamily: "cerebri-sans-semibold",
 },
 oxxoImage: {
  margin: "auto",
  height: 30,
  width: 122,
 },
});

export default Suscription;
