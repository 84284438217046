import axios from "axios";
import React, { Component } from "react";
import {AsyncStorage, Alert} from 'react-native';
//export const route = 'http://159.65.106.64'
//export const route = 'https://armapp.lagom.agency'
export const route = 'https://registro.armapp.mx'
//export const route = 'https://arma-test.lagom.agency'
//export const route = 'http://127.0.0.1:8000/'

export const logged_in = () => {
    if (localStorage.user_token == "") {
        return false;
    }
};

export const register = newUser => {
    return axios
        .post(`${route}/api/register`, newUser, {
            headers: { "Content-Type": "application/json" }
        })
        .then(res => {
            //console.log(res);
            return res;
        })
        .catch(err => {
            Alert.alert('','El correo que intenta registrar ya existe.')
        });
};

export const log_out = () => {
    AsyncStorage.setItem("user_token", '');
    Alert.alert('','Log out success');
};


/* export const getNiveles = () => {
    return axios
    .post('api/niveles', newUser, {
        headers:{ Authorization : `Bearer ${localStorage.user_token}`}
    })
    .then(res => {
        console.log(res)
        return res.data
    })
    .catch(err => {
        console.log(err)
    })
} */

export const getTipos = () => {
    return axios
        .get("api/tipos", {
            headers: { Authorization: `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export  const getUserData = data =>{
    return axios
    .get(`${route}/api/alumnos/${data.user_id}`, {
        headers: { Authorization: `Bearer ${data.token}` }
    })
    .then(res => {
        //console.log(res);
        return res.data.data;
    })
    .catch(err => {
        console.log(err);
    });
}

export const getTempOrder = user_token => {
    return axios
        .get(`${route}/api/get_temp_order`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getEspecialidades = user_token => {
    return axios
        .get(`${route}/api/especialidades`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getEspecialidadesImagenes = user_token => {
    return axios
        .get(`${route}/api/especialidades_imagenes`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCupones = user_token => {
    return axios
        .get(`${route}/api/cupones`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getTemas = user_token => {
    return axios
        .get(`${route}/api/temas`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getNiveles = user_token => {
    return axios
        .get(`${route}/api/niveles`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getNivelesEspecialidad = data => {
    return axios
        .get(`${route}/api/especialidad_niveles/${data.especialidad_id}`, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCasos = user_token => {
    return axios
        .get(`${route}/api/casos`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getPreguntas = user_token => {
    return axios
        .get(`${route}/api/preguntas`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getResouestas = user_token => {
    return axios
        .get(`${route}/api/respuestas`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getRoles = user_token => {
    return axios
        .get(`${route}/api/roles`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getUsuariosAdmin = user_token => {
    return axios
        .get(`${route}/api/administracion`, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getEspecialidadesWA = () => {
    return axios
        .get(`${route}/api/especialidades_wa`, {
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getUniversidadesWA = () => {
    return axios
        .get(`${route}/api/universidades_wa`, {
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getEstados = () => {
    return axios
        .get(`${route}/api/estados`, {
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};



export const getUserNivel = data  => {
    return axios
        .get(`${route}/api/nivel_test/${data.nivel_id}`, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};


export const getCuponByCodigo = data  => {
    return axios
        .get(`${route}/api/cupon/${data.codigo}`, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};


export const getSuscription = data => {
    return axios
        .get(`${route}/api/suscription`, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getPlanes = data => {
    return axios
        .get(`${route}/api/planes`, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getResultados = data => {
    return axios
        .get(`${route}/api/resultado_especialidad`, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCleanProgress = data => {
    return axios
        .get(`${route}/api/clean_user_process`, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res;
        })
        .catch(err => {
            console.log(err);
        });
};


export const getCasosCount = data => {
    return axios
        .get(`${route}/api/count_casos`, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getRanking = data => {
    return axios
        .get(`${route}/api/ranking`, {
            headers: { Authorization: `Bearer ${data}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};





// POST ROUTES

export const postPasswordEdit = data => {
    return axios
        .post(`${route}/api/change_password`, data, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};

export const postSuscribe = newSuscribe => {
    return axios
        .post(`${route}/api/subscribe`, newSuscribe, {
            headers: { Authorization: `Bearer ${newSuscribe.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const postEspecialidades = newEspecialidad => {
    return axios
        .post(`${route}/api/especialidades`, newEspecialidad, {
            headers: { Authorization: `Bearer ${newEspecialidad.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
            alert('true')
        });
};

export const login = user => {
    return axios
        .post(
            `${route}/api/login`,
            {
                email: user.email,
                password: user.password
            },
            {
                headers: { 
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json' }
            }
        )
        .then(res => {
            //console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};

export const postUpdateCountCaso = data => {
    return axios
        .post(`${route}/api/update_count_caso`, data, {
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
        })
        .catch(err => {
            console.log(err);
        });
};





export const postTemas = newTema => {
    return axios
        .post(`${route}/api/temas`, newTema, {
            headers: { Authorization: `Bearer ${newTema.user_token}` }
        })
        .then(res => {
            //console.log(res);
        })
        .catch(err => {
            console.log(err);
        });
};

export const postTipos = newTipo => {
    return axios
        .post(`${route}/api/tipos`, newTipo, {
            headers: { Authorization: `Bearer ${newTipo.user_token}` }
        })
        .then(res => {
            //console.log(res);
        })
        .catch(err => {
            console.log(err);
        });
};

export const postNiveles = newNivel => {
    return axios
        .post(`${route}/api/niveles`, newNivel, {
            headers: { Authorization: `Bearer ${newNivel.user_token}` }
        })
        .then(res => {
            //console.log(res);
        })
        .catch(err => {
            console.log(err);
        });
};

export const postCasos = newCaso => {
    return axios
        .post(`${route}/api/casos`, newCaso, {
            headers: { Authorization: `Bearer ${user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const postPreguntas = newPregunta => {
    return axios
        .post(`${route}/api/preguntas`, newPregunta, {
            headers: { Authorization: `Bearer ${newPregunta.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const postRespuestas = newRespuesta => {
    return axios
        .post(`${route}/api/respuestas`, newRespuesta, {
            headers: { Authorization: `Bearer ${newRespuesta.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const postAdministracion = newAdmin => {
    return axios
        .post(`${route}/api/administracion`, newAdmin, {
            headers: { Authorization: `Bearer ${newAdmin.user_token}` }
        })
        .then(res => {
            //console.log(res);
        })
        .catch(err => {
            console.log(err);
        });
};

export const postCupones = newCupon => {
    return axios
        .post(`${route}/api/cupones`, newCupon, {
            headers: { Authorization: `Bearer ${newCupon.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const postUsuariosAdmin = newUsuarioAdmin => {
    return axios
        .post(`${route}/api/register`, newUsuarioAdmin, {
        })
        .then(res => {
            //console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};


export function postTest(token,especialidad_id){
    return axios
        .post(`${route}/api/build_test/${especialidad_id}`,{} ,{
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const postValidateCupon = data =>{
    return axios
        .post(`${route}/api/validate_cupon`,data ,{
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const postSuscripcion = data =>{
    return axios
        .post(`${route}/api/suscripcion`,data ,{
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const postResponder = data =>{
    return axios
        .post(`${route}/api/responder`,data ,{
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
};


export const postPasswordRecover = data =>{
    return axios
        .post(`${route}/api/password/create`,data ,{
            headers: { Authorization: `Bearer ${data.token}` }
        })
        .then(res => {
            //console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
};



// DELETE ROUTES

export const deleteCasos = caso_id => {
    return axios
        .delete(`api/casos/${caso_id}`, {
            headers: { Authorization: `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteEspecialidad = especialidad_id => {
    return axios
        .delete(`api/especialidades/${especialidad_id}`, {
            headers: { Authorization: `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteTema = tema_id => {
    return axios
        .delete(`api/temas/${tema_id}`, {
            headers: { Authorization: `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteTipo = tema_id => {
    return axios
        .delete(`api/tipos/${tema_id}`, {
            headers: { Authorization: `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteCupon = cupon_id => {
    return axios
        .delete(`api/cupones/${cupon_id}`, {
            headers: { Authorization: `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            //console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};


// GET BY ID

export const getCasoById = caso_id =>{
    return axios
    .get(`api/casos/${caso_id}`,{
        headers: { Authorization: `Bearer ${localStorage.user_token}`}
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const getEspecialidadById = especialidad_id =>{
    return axios
    .get(`api/especialidades/${especialidad_id}`,{
        headers: { Authorization: `Bearer ${localStorage.user_token}`}
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const getTemasById = tema_id =>{
    return axios
    .get(`api/temas/${tema_id}`,{
        headers: { Authorization: `Bearer ${localStorage.user_token}`}
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const getTiposById = tipo_id =>{
    return axios
    .get(`api/tipos/${tipo_id}`,{
        headers: { Authorization: `Bearer ${localStorage.user_token}`}
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const getCuponesById = cupon_id =>{
    return axios
    .get(`api/cupones/${cupon_id}`,{
        headers: { Authorization: `Bearer ${localStorage.user_token}`}
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}
// PUT ROUTES  

export const putCasos = newCaso  => {
    return axios
    .put(`api/casos/${newCaso.caso_id}`, newCaso, {
        headers: { Authorization: `Bearer ${localStorage.user_token}` }
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const putPreguntas =  newPregunta  => {
    return axios
    .put(`api/preguntas/${newPregunta.id}`, newPregunta, {
        headers: { Authorization: `Bearer ${localStorage.user_token}` }
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const putRespuestas = newRespuesta  => {
    return axios
    .put(`api/respuestas/${newRespuesta.id}`, newRespuesta, {
        headers: { Authorization: `Bearer ${localStorage.user_token}` }
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const putEspecialidades = newEspecialidad  => {
    return axios
    .put(`api/especialidades/${newEspecialidad.id}`, newEspecialidad, {
        headers: { Authorization: `Bearer ${localStorage.user_token}` }
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const putTemas = newTema  => {
    return axios
    .put(`api/temas/${newTema.id}`, newTema, {
        headers: { Authorization: `Bearer ${localStorage.user_token}` }
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const putTipos = newTipo  => {
    return axios
    .put(`api/tipos/${newTipo.id}`, newTipo, {
        headers: { Authorization: `Bearer ${localStorage.user_token}` }
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const putCupones = newCupon  => {
    return axios
    .put(`api/cupones/${newCupon.id}`, newCupon, {
        headers: { Authorization: `Bearer ${localStorage.user_token}` }
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}

export const putAlumnos = data  => {
    return axios
    .put(`${route}/api/alumnos/${data.user_id}`, data, {
        headers: { Authorization: `Bearer ${data.token}` }
    })
    .then(res => {
        console.log(res)
        return res.data.data
    })
    .catch(err => {
        console.log(err)
    })
}