import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
 Image,
 Text,
 View,
 StyleSheet,
 SafeAreaView,
 Picker,
 ScrollView,
 TouchableOpacity,
 Alert,
 StatusBar,
 Linking,
} from "react-native";
import { Button, Dialog, TextInput } from "react-native-paper";
import {
 login,
 getEspecialidadesWA,
 getEstados,
 postUsuariosAdmin,
 register,
 getUniversidadesWA,
} from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import { KeyboardAvoidingView } from "react-native";
import RNPickerSelect from "react-native-picker-select";
import * as Font from "expo-font";
import SpinnerButton from "react-native-spinner-button";

class Signup extends Component {
 state = {
  nombre: "",
  email: "",
  password: "",
  c_password: "",
  celular: "",
  estado: "",
  ciudad: "",
  universidad: "",
  especialidad: "",
  intentos_enarm: "",
  especialidades: [],
  estados: [],
  universidades: [],
  error: "",
  nombreError: "",
  emailError: "",
  passwordError: "",
  c_passwordError: "",
  celularError: "",
  estadoError: "",
  ciudadError: "",
  universidadError: "",
  especialidadError: "",
  intentosError: "",
 };

 componentDidMount() {
  getEspecialidadesWA().then((res) => {
   this.setState({
    especialidades: res,
   });
  });
  getEstados().then((res) => {
   this.setState({
    estados: res,
   });
  });
  getUniversidadesWA().then((res) => {
   this.setState({
    universidades: res,
   });
  });
  Font.loadAsync({
   "cerebri-sans-semibold": require("../../assets/fonts/CerebriSans-SemiBold.ttf"),
  });

  Font.loadAsync({
   "cerebri-sans-regular": require("../../assets/fonts/CerebriSans-Regular.ttf"),
  });
 }

 validate_fields = () => {
  let nombreError = "";
  let emailError = "";
  let passwordError = "";
  let c_passwordError = "";
  let celularError = "";
  let estadoError = "";
  let ciudadError = "";
  let universidadError = "";
  let especialidadError = "";
  let intentosError = "";

  if (this.state.nombre == "") {
   nombreError = "Nombre no puede ser vacio";
  }

  if (this.state.email == "") {
   emailError = "Correo no puede ser vacio";
  }

  if (this.state.password == "") {
   passwordError = "Contraseña no puede ser vacia";
  } else if (this.state.password.length < 8) {
   passwordError = "Contraseña no puede ser menor de 8 caracteres";
  }
  if (this.state.c_password !== this.state.password) {
   c_passwordError = "Las contraseñas no coinciden";
  }
  /*
    if (this.state.celular == "") {
      celularError = "Celular no puede ser vacio";
    }
    */
  if (this.state.estado == "") {
   estadoError = "Selecciona un estado";
  }
  if (this.state.ciudad == "") {
   ciudadError = "Ciudad no puede ser vacia";
  }
  if (this.state.universidad == "") {
   universidadError = "Universidad no puede ser vacia";
  }
  if (this.state.especialidad == "") {
   especialidadError = "Selecciona una especialidad";
  }
  if (this.state.intentos_enarm == "") {
   intentosError = "Intentos no puede ser vacio";
  }

  if (
   nombreError ||
   emailError ||
   passwordError ||
   c_passwordError ||
   celularError ||
   estadoError ||
   ciudadError ||
   universidadError ||
   especialidadError ||
   intentosError
  ) {
   this.setState({
    nombreError,
    emailError,
    passwordError,
    c_passwordError,
    celularError,
    estadoError,
    ciudadError,
    universidadError,
    especialidadError,
    intentosError,
   });
   return false;
  } else {
   this.setState({
    nombreError,
    emailError,
    passwordError,
    c_passwordError,
    celularError,
    estadoError,
    ciudadError,
    universidadError,
    especialidadError,
    intentosError,
   });
   return true;
  }
 };

 handleCreate = () => {
  this.setState({ loading: true });
  const newUser = {
   name: this.state.nombre,
   email: this.state.email,
   password: this.state.password,
   c_password: this.state.c_password,
   numero: this.state.celular,
   estado_id: parseInt(this.state.estado),
   ciudad: this.state.ciudad,
   especialidad_id: parseInt(this.state.especialidad),
   intentos_e: this.state.intentos_enarm,
   universidad: this.state.universidad,
   role_id: 4,
  };
  const isValid = this.validate_fields();
  if (isValid) {
   register(newUser).then((res) => {
    if (res) {
     const { navigate } = this.props.navigation;
     navigate("Start");
    } else {
     this.setState({
      error: true,
      loading: false
     });
    }
   });
  } else {
   Alert.alert("", "Revisa los campos.");
   setTimeout(() => {
    this.setState({ loading: false });
   }, 2000);
  }
 };

 onFocusChange = () => {
  this.setState({ isFocused: true });
 };
 render() {
  var heightScreen = Dimensions.get("window").height;
  return (
   <>
    <StatusBar barStyle="light-content" backgroundColor="#ED1B24" />
    {heightScreen > 780 ? (
     <View style={styles.topBarExtra}></View>
    ) : (
     <View style={styles.topBarExtraMin}></View>
    )}
    <View style={styles.topBar}>
     <TouchableOpacity
      style={styles.backButton}
      onPress={() => this.props.navigation.navigate("Start")}
     >
      <Image
       style={styles.backButtonIcon}
       source={require("../../assets/back.png")}
      />
     </TouchableOpacity>
     <Text style={styles.headerTitle}>Crear Cuenta</Text>
     <TouchableOpacity style={styles.backButton}>
      <Image
       style={styles.backButtonIcon}
       source={require("../../assets/backno.png")}
      />
     </TouchableOpacity>
    </View>
    <View style={styles.container} behavior="padding">
     <ScrollView
      behavior="padding"
      ref="scroll"
      showsVerticalScrollIndicator={false}
     >
      <TextInput
       label="Nombre Completo"
       placeholder="Nombre Completo"
       value={this.state.nombre}
       autoCapitalize="words"
       onChangeText={(nombre) => this.setState({ nombre })}
       onFocus={this.onFocusChange}
       theme={{
        colors: { primary: "#000", placeholder: "#aaa" },
        fonts: {
         regular: {
          fontFamily: "cerebri-sans-regular",
          fontWeight: "normal",
         },
        },
       }}
       style={styles.inputText}
       underlineColor="#E2E2E2"
       //mode={'flat'}
      />
      <Text style={styles.errorText}>{this.state.nombreError}</Text>
      <TextInput
       label="Correo Electrónico"
       placeholder="Correo Electrónico"
       autoCapitalize="none"
       style={styles.inputText}
       value={this.state.email}
       autoCapitalize="none"
       onChangeText={(email) => this.setState({ email })}
       theme={{
        colors: { primary: "#000", placeholder: "#aaa" },
        fonts: {
         regular: {
          fontFamily: "cerebri-sans-regular",
          fontWeight: "normal",
         },
        },
       }}
       underlineColor="#E2E2E2"
       keyboardType="email-address"
      />
      <Text style={styles.errorText}>{this.state.emailError}</Text>
      <TextInput
       label="Crear Contraseña"
       placeholder="Crear Contraseña"
       style={styles.inputText}
       value={this.state.password}
       onChangeText={(password) => this.setState({ password })}
       theme={{
        colors: { primary: "#000", placeholder: "#aaa" },
        fonts: {
         regular: {
          fontFamily: "cerebri-sans-regular",
          fontWeight: "normal",
         },
        },
       }}
       underlineColor="#E2E2E2"
       secureTextEntry={true}
      />
      <Text style={styles.errorText}>{this.state.passwordError}</Text>
      <TextInput
       label="Confirmar Contraseña"
       placeholder="Confirmar Contraseña"
       style={styles.inputText}
       value={this.state.c_password}
       onChangeText={(c_password) => this.setState({ c_password })}
       theme={{
        colors: { primary: "#000", placeholder: "#aaa" },
        fonts: {
         regular: {
          fontFamily: "cerebri-sans-regular",
          fontWeight: "normal",
         },
        },
       }}
       underlineColor="#E2E2E2"
       secureTextEntry={true}
      />
      <Text style={styles.errorText}>{this.state.c_passwordError}</Text>
      {/* 
          <TextInput
            label="Número Celular"
            placeholder="Número Celular"
            style={styles.inputText}
            value={this.state.celular}
            onChangeText={(celular) => this.setState({ celular })}
            theme={{
              colors: { primary: "#000", placeholder: "#aaa" },
              fonts: {
                regular: {
                  fontFamily: "cerebri-sans-regular",
                  fontWeight: "normal",
                },
              },
            }}
            underlineColor="#E2E2E2"
            keyboardType="number-pad"
          />
          <Text style={styles.errorText}>{this.state.celularError}</Text>
          */}

      <View style={styles.doubleInput}>
       <View style={styles.doubleInputItem}>
        <RNPickerSelect
         onValueChange={(value) => this.setState({ estado: value })}
         style={{
          ...pickerSelectStyles,
          iconContainer: {
           top: 25,
           right: 12,
          },
          placeholder: {
           fontFamily: "cerebri-sans-regular",
          },
         }}
         placeholder={{
          label: "Estado",
          value: null,
         }}
         placeholderTextColor="#aaa"
         Icon={() => {
          return (
           <View
            style={{
             backgroundColor: "transparent",
             borderTopWidth: 5,
             borderTopColor: "#000",
             borderRightWidth: 5,
             borderRightColor: "transparent",
             borderLeftWidth: 5,
             borderLeftColor: "transparent",
             width: 0,
             height: 0,
            }}
           />
          );
         }}
         items={this.state.estados.map((estado) => {
          return { label: estado.name, value: estado.id };
         })}
        />
        <Text style={styles.errorText}>{this.state.estadoError}</Text>
       </View>
       <View style={styles.doubleInputItem}>
        <TextInput
         label="Ciudad"
         placeholder="Ciudad"
         style={styles.inputText}
         value={this.state.ciudad}
         onChangeText={(ciudad) => this.setState({ ciudad })}
         theme={{
          colors: { primary: "#000", placeholder: "#aaa" },
          fonts: {
           regular: {
            fontFamily: "cerebri-sans-regular",
            fontWeight: "normal",
           },
          },
         }}
         underlineColor="#E2E2E2"
        />
        <Text style={styles.errorText}>{this.state.ciudadError}</Text>
       </View>
      </View>
      <RNPickerSelect
       onValueChange={(value) => this.setState({ universidad: value })}
       style={{
        ...pickerSelectStyles,
        iconContainer: {
         top: 25,
         right: 12,
        },
        placeholder: {
         fontFamily: "cerebri-sans-regular",
        },
       }}
       placeholder={{
        label: "Universidad",
        value: null,
       }}
       placeholderTextColor="#aaa"
       Icon={() => {
        return (
         <View
          style={{
           backgroundColor: "transparent",
           borderTopWidth: 5,
           borderTopColor: "#000",
           borderRightWidth: 5,
           borderRightColor: "transparent",
           borderLeftWidth: 5,
           borderLeftColor: "transparent",
           width: 0,
           height: 0,
          }}
         />
        );
       }}
       items={this.state.universidades.map((universidad) => {
        return { label: universidad.name, value: universidad.name };
       })}
      />
      <Text style={styles.errorText}>{this.state.universidadError}</Text>
      <RNPickerSelect
       onValueChange={(value) => this.setState({ especialidad: value })}
       style={{
        ...pickerSelectStyles,
        iconContainer: {
         top: 25,
         right: 12,
        },
        placeholder: {
         fontFamily: "cerebri-sans-regular",
        },
        fontFamily: "cerebri-sans-regular",
       }}
       placeholder={{
        label: "Especialidad",
        value: null,
       }}
       placeholderTextColor="#aaa"
       Icon={() => {
        return (
         <View
          style={{
           backgroundColor: "transparent",
           borderTopWidth: 5,
           borderTopColor: "#000",
           borderRightWidth: 5,
           borderRightColor: "transparent",
           borderLeftWidth: 5,
           borderLeftColor: "transparent",
           width: 0,
           height: 0,
          }}
         />
        );
       }}
       items={this.state.especialidades.map((especialidad) => {
        return { label: especialidad.name, value: especialidad.id };
       })}
      />
      <Text style={styles.errorText}>{this.state.especialidadError}</Text>
      <TextInput
       placeholder="¿Número de intentos ENARM?"
       label="¿Número de intentos ENARM?"
       style={styles.inputText}
       value={this.state.intentos_enarm}
       onChangeText={(intentos_enarm) => this.setState({ intentos_enarm })}
       theme={{
        colors: { primary: "#000", placeholder: "#aaa" },
        fonts: {
         regular: {
          fontFamily: "cerebri-sans-regular",
          fontWeight: "normal",
         },
        },
       }}
       underlineColor="#E2E2E2"
       keyboardType="number-pad"
      />
      <Text style={styles.errorText}>{this.state.intentosError}</Text>
      <TouchableOpacity
       style={styles.buttonCreate}
       onPress={() => {
        Linking.openURL(`https://armapp.lagom.agency/privacidad`);
       }}
      >
       <Text style={styles.errorText}>
        Al registrarte aceptas nuestras politicas de privacidad.{" "}
       </Text>
      </TouchableOpacity>
      <SpinnerButton
       buttonStyle={styles.buttonMain}
       isLoading={this.state.loading}
       onPress={this.handleCreate}
       indicatorCount={10}
      >
       <Text style={styles.buttonText}>Crear Cuenta</Text>
      </SpinnerButton>
     </ScrollView>
    </View>
   </>
  );
 }
}

const styles = StyleSheet.create({
 container: {
  flex: 1,
  backgroundColor: "white",
  padding: 20,
  paddingTop: 20,
 },
 inputText: {
  backgroundColor: "white",
  height: 55,
  color: "#000",
  paddingLeft: 0,
  fontFamily: "cerebri-sans-regular",
  fontSize: 14,
  paddingHorizontal: 0,
 },
 buttonCreate: {
  backgroundColor: "white",
  alignItems: "center",
 },
 doubleInput: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
 },
 doubleInputItem: {
  width: "48%",
 },
 buttonMain: {
  backgroundColor: "#ED1B24",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#fff",
  display: "flex",
  fontWeight: "500",
  fontSize: 13,
  height: 46,
  justifyContent: "center",
  marginTop: 30,
  width: "100%",
  marginBottom: 20,
 },
 buttonText: {
  textAlign: "center",
  color: "white",
  fontWeight: "700",
  fontFamily: "cerebri-sans-semibold",
 },
 errorText: {
  fontSize: 11,
  color: "#ED1B24",
  fontFamily: "cerebri-sans-regular",
  marginTop: 5,
 },
 headerTitle: {
  fontSize: 18,
  fontWeight: "500",
  color: "#fff",
  fontFamily: "cerebri-sans-semibold",
 },
 backButtonIcon: {
  height: 20,
  width: 20,
 },
 topBar: {
  alignItems: "center",
  backgroundColor: "#ED1B24",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: 45,
  paddingLeft: 20,
  paddingRight: 20,
 },
 topBarExtra: {
  backgroundColor: "#ED1B24",
  height: 45,
 },
 topBarExtraMin: {
  backgroundColor: "#ED1B24",
  height: 25,
 },
 startButtonText: {
  color: "#ED1B24",
  fontSize: 13,
  fontWeight: "600",
  textAlign: "right",
 },
});

const pickerSelectStyles = StyleSheet.create({
 inputIOS: {
  backgroundColor: "white",
  borderBottomColor: "#E2E2E2",
  borderBottomWidth: 1,
  fontFamily: "cerebri-sans-regular",
  height: 55,
  color: "#000",
  paddingLeft: 0, // to ensure the text is never behind the icon
 },
 inputAndroid: {
  backgroundColor: "white",
  borderBottomColor: "#E2E2E2",
  borderBottomWidth: 1,
  fontFamily: "cerebri-sans-regular",
  height: 55,
  color: "#000",
  paddingLeft: 0, // to ensure the text is never behind the icon
 },
});

export default Signup;
