import React, { Component } from "react";
import { Dimensions, Platform, ScrollView } from "react-native";
import {
 Image,
 Text,
 View,
 Button,
 StyleSheet,
 TouchableOpacity,
 AsyncStorage,
 StatusBar,
 ImageBackground,
} from "react-native";
import styled from "styled-components";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";
import { Linking } from "expo";
import * as WebBrowser from 'expo-web-browser';

class Start extends Component {
 state = {
  fontLoaded: false,
 };
 async componentDidMount() {

  if (Platform.OS === 'web') {
      this.props.navigation.navigate("IndexEspecialidades");
  }

  await Font.loadAsync({
   "cerebri-sans-semibold": require("../assets/fonts/CerebriSans-SemiBold.ttf"),
  });

  await Font.loadAsync({
   "cerebri-sans-regular": require("../assets/fonts/CerebriSans-Regular.ttf"),
  });

  this.setState({ fontLoaded: true });
 }
 render() {
  var heightScreen = Dimensions.get("window").height;
  return (
   <View >
    <StatusBar barStyle="light-content" backgroundColor="#ED1B24" />
    {this.state.fontLoaded ? (
     <>
      <ScrollView>
       <ImageBackground
        source={require("../assets/startBackground.png")}
        style={{ width: "100%", height: "100%" }}
       >
        <View style={styles.startIntro}>
         <Svg
          width="78"
          height="20"
          viewBox="0 0 78 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
         >
          <Path
           fill-rule="evenodd"
           clip-rule="evenodd"
           d="M7.33514 2.84251L0 19.4941L16.8484 19.466L15.1621 15.3769L6.66065 15.3909L11.073 5.10488C11.4805 4.26176 12.3939 4.55685 12.5344 5.21729L18.7735 19.452H23.6074L16.1598 2.64578C15.1621 0.13047 9.17596 -0.473766 7.33514 2.84251ZM73.1828 4.73953C71.5247 -0.937483 65.9601 -1.99138 63.6134 4.20555L61.5197 11.8217L59.4821 4.58495C58.7233 1.85887 56.6717 0.425562 54.5358 0.467718C52.6247 0.495822 50.531 1.63403 49.5193 4.43038L45.121 19.4239H49.983L53.6365 6.91759C54.0721 4.95031 55.4352 5.10488 55.8708 7.19863L59.1589 19.4098H63.9928V19.3536L67.3653 6.94569C67.7166 5.38592 69.178 4.50064 69.7963 7.01595L73.1828 19.4098H77.4827L73.1828 4.73953ZM36.2682 11.9482C40.9335 11.892 43.8282 9.92472 43.7579 6.45387C43.6736 2.63173 40.8351 0.76281 38.095 0.62229L24.9985 0.537978V19.4801H29.5233V13.0864H29.8746L43.9406 20V15.2785L36.2682 11.9482ZM29.453 8.30873V4.96436L37.3924 4.95031C39.6829 5.35781 39.9498 8.06985 37.3924 8.29468L29.453 8.30873Z"
           fill="white"
          />
         </Svg>

         <Text style={styles.startTitle}>
          Prepárate{"\n"}
          para el ENARM
         </Text>
         <Text style={styles.startSubtitle}>
          Simula el examen de residencias médicas
         </Text>
        </View>

        {heightScreen > 780 ? (
         <View style={styles.doctorRow}>
          <Image
           source={require("../assets/doctorStart.png")}
           style={styles.doctorRowVector}
          />
         </View>
        ) : (
         <View style={styles.doctorRowMin}>
          <Image
           source={require("../assets/doctorStart.png")}
           style={styles.doctorRowVector}
          />
         </View>
        )}

        <View style={styles.containerForm}>
         <TouchableOpacity
          style={styles.buttonMain}
          onPress={() => this.props.navigation.navigate("Login")}
         >
          <Text style={styles.buttonText}>Iniciar Sesión</Text>
         </TouchableOpacity>
         <TouchableOpacity
          style={styles.buttonSecondary}
          //onPress={() => this.props.navigation.navigate("Signup")}
          onPress={() => { 
                WebBrowser.openBrowserAsync('https://registro.armapp.mx/');
            }}
         >
          <Text style={styles.buttonText}>Crear Cuenta</Text>
         </TouchableOpacity>
        </View>
       </ImageBackground>
      </ScrollView>
     </>
    ) : null}
   </View>
  );
 }
}

var heightHalf = Dimensions.get("window").height / 3;
var heightTitle = Dimensions.get("window").height / 24;

const styles = StyleSheet.create({
 container: {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
 },
 backgroundImage: {
  flex: 1,
  resizeMode: "cover",
 },
 logoContainer: {
  alignItems: "center",
  flexGrow: 1,
  justifyContent: "flex-start",
  height: 540,
 },
 containerForm: {
  padding: 20,
 },
 buttonLogin: {
  backgroundColor: "red",
  padding: 10,
  marginBottom: 10,
 },
 buttonText: {
  textAlign: "center",
  color: "white",
  fontWeight: "700",
  fontSize: 14,
  fontFamily: "cerebri-sans-semibold",
 },
 buttonCreate: {
  backgroundColor: "black",
  padding: 10,
  marginBottom: 10,
 },
 loginIntroLogo: {
  marginBottom: 40,
  marginTop: 60,
 },
 startTitle: {
  fontSize: heightTitle,
  fontWeight: "500",
  color: "#fff",
  fontFamily: "cerebri-sans-semibold",
  marginTop: "7%",
 },
 startSubtitle: {
  fontSize: 14,
  fontWeight: "400",
  color: "#fff",
  opacity: 0.7,
  marginTop: "5%",
  fontFamily: "cerebri-sans-regular",
 },
 containerForm: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  width: "100%",
  padding: 35,
  paddingTop: 0,
 },
 buttonMain: {
  backgroundColor: "#ED1B24",
  borderColor: "transparent",
  borderRadius: 8,
  color: "#fff",
  display: "flex",
  fontWeight: "500",
  fontFamily: "cerebri-sans-semibold",
  fontSize: 13,
  height: 48,
  justifyContent: "center",
  width: "100%",
  marginBottom: 15,
 },
 buttonSecondary: {
  backgroundColor: "#000",
  borderColor: "transparent",
  borderRadius: 8,
  color: "#fff",
  display: "flex",
  fontWeight: "500",
  fontFamily: "cerebri-sans-semibold",
  fontSize: 13,
  height: 48,
  justifyContent: "center",
  width: "100%",
 },
 startIntro: {
  padding: 50,
  marginTop: "5%",
 },
 doctorImg: {
  height: "30%",
  width: "auto",
 },
 doctorRow: {
  padding: "12%",
  paddingBottom: 0,
  paddingTop: 0,
  textAlign: "center",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "8%",
  marginTop: "15%",
 },
 doctorRowMin: {
  padding: "8%",
  paddingBottom: 0,
  paddingTop: 0,
  textAlign: "center",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "5%",
  marginTop: "5%",
 },
 doctorRowVector: {
  height: heightHalf,
  resizeMode: "contain",
 },
});

export default Start;
