import React, { Component } from "react";
import { Dimensions, Platform } from "react-native";

import {
 Image,
 Text,
 TextInput,
 View,
 StyleSheet,
 SafeAreaView,
 TouchableOpacity,
 Alert,
 StatusBar,
 ScrollView,
} from "react-native";
import { Button, Divider, List, Avatar, ProgressBar } from "react-native-paper";
import {
 getCupones,
 postValidateCupon,
 postSuscripcion,
 getCuponByCodigo,
 getResultados,
 getCleanProgress,
} from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Rect, Circle } from "react-native-svg";
import * as Font from "expo-font";
import { globalStyles } from "../Layout";
// import { AnimatedCircularProgress } from "react-native-circular-progress";

class Resultados extends Component {
 state = {
  promedio_general: 0,
  promedios_especialidad: [],
  fill: 0,
 };
 
 cleanProgress = () => {
  const data = {
   token: this.state.token,
  };

  
  getCleanProgress(data).then((res) => {
    this.props.navigation.naviigate("IndexEspecialidades")
  });
 };
 
 componentDidMount() {
  this.loadResultados();
  this.props.navigation.addListener("willFocus", this._handleStateChange);
}

_handleStateChange = (state) => {
  this.loadResultados();
};

 loadResultados = () => {
  Font.loadAsync({
    "cerebri-sans-semibold": require("../../assets/fonts/CerebriSans-SemiBold.ttf"),
   });
 
   Font.loadAsync({
    "cerebri-sans-regular": require("../../assets/fonts/CerebriSans-Regular.ttf"),
   });
   
   if (Platform.OS === 'web') {
    const token = localStorage.getItem('user_token');
    const user_name = localStorage.getItem('user_name');

    getResultados({ token }).then((res) => {
      this.setState(
       {
        promedio_general: res.data.data.promedio_general,
        promedios_especialidad: res.data.data.promedios_especialidad,
       },
       () => {
        const answered = [];
        this.state.promedios_especialidad.map((resultado) => {
         if (resultado.etapa_3.promedio >= 0) {
          answered.push(resultado);
         }
        });
        const progreso_general =
         (answered.length * 100) / this.state.promedios_especialidad.length;
        this.setState({
         fill: progreso_general.toFixed(0),
         token,
         user_name
        });
       }
      );
      //console.log(res);
     });
   } else {
    AsyncStorage.getItem("user_token", (error, result) => {
      const data = {
       token: result,
      };
      getResultados(data).then((res) => {
       this.setState(
        {
         promedio_general: res.data.data.promedio_general,
         promedios_especialidad: res.data.data.promedios_especialidad,
        },
        () => {
         const answered = [];
         this.state.promedios_especialidad.map((resultado) => {
          if (resultado.etapa_3.promedio >= 0) {
           answered.push(resultado);
          }
         });
         const progreso_general =
          (answered.length * 100) / this.state.promedios_especialidad.length;
         this.setState({
          fill: progreso_general.toFixed(0),
         });
        }
       );
       //console.log(res);
      });
      this.setState(
       {
        token: result,
       },
       () => {
        AsyncStorage.getItem("user_name", (error, result) => {
         this.setState({
          user_name: result,
         });
        });
       }
      );
     });
   }
 }

 render() {
  return (
  <View style={globalStyles.globalContainer}>
   <ScrollView style={styles.container}>
    <StatusBar barStyle="light-content" backgroundColor="#ED1B24" />
    <View style={styles.statsContainer}>
     <View style={styles.stats}>
      <View style={styles.statsRight}>
       <Text style={styles.statsTitle}>
        {this.state.promedios_especialidad.length}
       </Text>
       <Text style={styles.statsSubtitle}>Especialidades</Text>
      </View>
      <View style={styles.statsCenter}>
       {/* <Text style={styles.statsTitle}>13%</Text> */}
       {/* Platform.OS !== 'web' && (
         <AnimatedCircularProgress
         size={80}
         width={10}
         fill={this.state.fill}
         tintColor="#fff"
         onAnimationComplete={() => console.log("onAnimationComplete")}
         backgroundColor="#353535"
        >
         {(fill) => <Text style={styles.statsTotal}>{this.state.fill}%</Text>}
        </AnimatedCircularProgress>
       )*/}
      </View>
      <View style={styles.statsRight}>
       <Text style={styles.statsTitle}>
        {this.state.promedio_general.toFixed(2)}
       </Text>
       <Text style={styles.statsSubtitle}>Promedio</Text>
      </View>
     </View>
     <View style={styles.containerBottom}>
      <View style={styles.resultAdvice}>
       <Text style={styles.resultAdviceText}>
       Los resultados que se muestran son en base a 100. Se calcula con el total de tus preguntas correctas entre las preguntas de la aplicación por 100.
       </Text>
      </View>
     </View>
     {this.state.fill == 0 ?
     <View style={styles.containerBottom}>
     <Image
      source={require("../../assets/doctorStart.png")}
      style={styles.doctorRow}
     />
     <Text style={styles.doctorRowText}>
      Termina las tres etapas de cualquier especialidad para obtener su
      resultados.
     </Text>
    </View>
    :
    <ScrollView
      style={Platform.OS === 'web' ? globalStyles.screenContainerDesktop : globalStyles.screenContainerMobile}
    >
     <List.Section style={styles.especialidadesList}>
      {this.state.promedios_especialidad.map((resultado, index) =>
       (resultado.etapa_1.promedio && resultado.etapa_3.promedio) >= 0 ? (
        <>
         <View key={index}>
          <List.Item
           title={resultado.especialidad.name}
           titleStyle={{
            textTransform: "capitalize",
            fontFamily: "cerebri-sans-regular",
            left: 15,
           }}
           style={styles.especialidadesListItem}
           left={() => (
            <Avatar.Text
             style={{
              backgroundColor: `${resultado.especialidad.hexa_color}`,
              top: 0,
              fontFamily: "cerebri-sans-regular",
             }}
             color="#fff"
             size={36}
             label={resultado.especialidad.name.charAt(0)}
             labelStyle={{
              fontFamily: "cerebri-sans-semibold",
             }}
            />
           )}
          />
         </View>
         <View style={styles.resultRow}>
          <ProgressBar
           progress={resultado.etapa_1.promedio / 100}
           color={resultado.especialidad.hexa_color}
           style={styles.progressBarStyle}
          />
          <Text style={styles.resultRowText}>
           {resultado.etapa_1.etapa +
            " - " +
            parseFloat(resultado.etapa_1.promedio).toFixed(2)}
          </Text>
         </View>
         <View style={styles.resultRow}>
          <ProgressBar
           progress={resultado.etapa_3.promedio / 100}
           color={resultado.especialidad.hexa_color}
           style={styles.progressBarStyle}
          />
          <Text style={styles.resultRowText}>
           {resultado.etapa_3.etapa +
            " - " +
            parseFloat(resultado.etapa_3.promedio).toFixed(2)}
          </Text>
         </View>
        </>
       ) : null
      )}
     </List.Section>
    </ScrollView>
     
     }

     {/*<TouchableOpacity style={styles.buttonMain} onPress={()=>{ this.cleanProgress() }} hidden>
            <Text style={styles.buttonText}>Restablecer Resultados</Text>
    </TouchableOpacity>*/}
     
    </View>
   </ScrollView>
   </View>
  );
 }
}

var width = Dimensions.get("window").width;
var heightHalf = Dimensions.get("window").height / 4;

const styles = StyleSheet.create({
 container: {
  flex: 1,
  backgroundColor: "white",
 },
 containerBottom: {
  padding: 20,
  paddingTop: 0,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
 },
 statsContainer: {
  display: "flex",
 },
 text: {
  marginTop: 15,
  marginBottom: 15,
 },
 inputText: {
  backgroundColor: "white",
  borderBottomColor: "#E2E2E2",
  borderBottomWidth: 1,
  height: 50,
  color: "#000",
  paddingLeft: 0,
 },
 buttonMain: {
  backgroundColor: "#F2F2F2",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#fff",
  display: "flex",
  fontWeight: "500",
  height: 46,
  justifyContent: "center",
  marginTop: 30,
  width: "100%",
 },
 buttonText: {
  textAlign: "center",
  color: "black",
  fontWeight: "700",
  textTransform: "capitalize",
  letterSpacing: 0,
  fontFamily: "cerebri-sans-semibold",
  fontSize: 14,
 },
 headerTitle: {
  fontSize: 18,
  fontWeight: "500",
  color: "#000",
  fontFamily: "cerebri-sans-semibold",
 },
 backButtonIcon: {
  height: 20,
  width: 20,
 },
 topBar: {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: 30,
  marginBottom: 20,
  marginTop: 10,
 },
 startButtonText: {
  color: "#ED1B24",
  fontSize: 13,
  fontWeight: "600",
  textAlign: "right",
 },
 stats: {
  backgroundColor: "#111",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: 35,
  paddingLeft: 20,
  paddingRight: 20,
 },
 statsTitle: {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: 22,
  lineHeight: 22,
  color: "#fff",
  margin: "auto",
  textAlign: "center",
  fontFamily: "cerebri-sans-semibold",
 },
 statsSubtitle: {
  fontWeight: "normal",
  fontSize: 11,
  lineHeight: 11,
  color: "#B8B8B8",
  textAlign: "center",
  marginTop: 12,
 },
 statsRight: {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "33%",
 },
 statsCenter: {
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  width: "33%",
 },
 dividerLine: {
  marginBottom: 30,
  marginTop: 30,
 },
 dividerLineTop: {
  marginBottom: 30,
 },
 especialidadesList: {
  paddingBottom: 100,
  paddingTop: 0,
  marginTop: -10,
  padding: 25,
  display: "flex",
  alignItems: "stretch",
 },
 especialidadesListSpec: {
  backgroundColor: "#F8BC47",
 },
 especialidadesListPart: {
  fontFamily: "cerebri-sans-semibold",
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  borderColor: "white",
  color: "#000",
  top: 5,
  fontSize: 14,
 },
 especialidadesListItem: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 80,
  paddingBottom: 20,
  fontFamily: "cerebri-sans-regular",
 },
 especialidadesListItemAction: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
 },
 especialidadesListPart: {
  fontSize: 14,
  display: "flex",
  fontFamily: "cerebri-sans-semibold",
  alignItems: "center",
  color: "#000",
  marginRight: 10,
 },
 progressBarStyle: {
  width: 200,
  height: 7,
 },
 resultAdvice: {
  backgroundColor: "#ECECEC",
  borderRadius: 6,
  marginTop: 40,
  marginBottom: 40,
  padding: 20,
 },
 resultAdviceText: {
  color: "#425560",
  fontSize: 11,
 },
 doctorRow: {
  height: heightHalf,
  resizeMode: "contain",
 },
 doctorRowText: {
  fontFamily: "cerebri-sans-regular",
  fontSize: 14,
  lineHeight: 18,
  textAlign: "center",
  width: "60%",
  marginTop: 35,
 },
 statsTotal: {
  fontSize: 16,
  color: "#fff",
  fontFamily: "cerebri-sans-semibold",
 },
 resultRow: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 12,
 },
 resultRowText: {
  fontSize: 11,
  lineHeight: 14,
  color: "#666",
 },
});

export default Resultados;
