import React, { useEffect, useState } from 'react';
import { View, Text, Dimensions, StyleSheet, Alert } from 'react-native';
import { getEstados } from '../ArmFunctions';

const { width } = Dimensions.get('window');

function MiniOfflineSign() {
  return (
    <View style={styles.offlineContainer}>
      <Text style={styles.offlineText}>No hay conexión a Internet</Text>
    </View>
    
  );
}


const OfflineNotice  = () => {
 
    const [isInternetReachable, SetIsInternetReachable] = useState(true);
    useEffect(()=>{
       getEstados().then(res=>{
        if(!res){
            SetIsInternetReachable(false)
        }else{
            SetIsInternetReachable(true)
        }
       })
    },[]);
    if (isInternetReachable) {
      return null;
    }else{
        return <MiniOfflineSign />;
    }
    
  }


const styles = StyleSheet.create({
  offlineContainer: {
    backgroundColor: '#b52424',
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width,
    position: 'absolute',
    top: 30
  },
  offlineText: { color: '#fff' }
});

export default OfflineNotice;
