import React, { Component } from "react";
import { createStackNavigator } from "react-navigation-stack";
import { createAppContainer, SafeAreaView } from "react-navigation";
import { Appbar, BottomNavigation, Text, List } from "react-native-paper";
import Start from "./Start";
import Login from "./Login/Login";
import Index from "./Index/Welcome";
import {
  StyleSheet,
  Dimensions,
  View,
  ScrollView,
  TouchableHighlight,
  Platform
} from "react-native";
import Signup from "./Register/SignUp";
import Instructions from "./Recover/Instructions";
import IndexEspecialidades from "./Especialidades/Index";
import * as Font from "expo-font";
import Stages from "./Tests/Stages";
import Casos from "./Tests/Casos";
import Resultados from "./Tests/Resultados";
import Ranking from "./Tests/Ranking";
import Suscription from "./Index/Suscription";
import Cupon from "./Index/Cupon";
import SuscriptionCupon from "./Index/Suscription-Cupon";
import {
  createDrawerNavigator,
  DrawerNavigatorItems,
} from "react-navigation-drawer";
import Header from "./Navigation/header";
import Svg, { Path, Rect } from "react-native-svg";
import { MaterialIcons } from "@expo/vector-icons";
import Profile from "./Register/Profile";
import { SafeAreaContext } from "react-native-safe-area-context";
import { log_out } from "./ArmFunctions";
import PasswordEdit from "./Register/PasswordEdit";
import RecoverSuccess from "./Recover/RecoverSuccess";
import PendingPayment from "./Index/PendingPayment";
import IndexIngles from "./Ingles/Index";
import IndexAleatorio from "./Aleatorio/Index";
import IndexImagenes from "./Imagenes/Index";

/*

const AppNavigator = createDrawerNavigator
(
  {
    Start: { screen: Start },
    Login: { screen: Login },
    Index: { screen: Index },
    Signup: { screen: Signup },
    Instrucciones: { screen: Instructions },
    IndexEspecialidades: { screen: IndexEspecialidades },
    Stages: { screen: Stages },
    Casos: { screen: Casos },
    Suscription: { screen: Suscription },
    Cupon: { screen: Cupon },
    SuscriptionCupon: { screen : SuscriptionCupon }
  }
);

const Container = createAppContainer(AppNavigator);
*/

const StartScreens = {
  Start: {
    screen: Start,
    navigationOptions: {
      title: "Start",
      header: false,
    },
  },
  Login: {
    screen: Login,
    navigationOptions: {
      title: "Login",
      header: false,
    },
  },
  PendingPayment: {
    screen: PendingPayment,
    navigationOptions: {
      title: "Oxxo Pay",
      header: false,
    },
  },
  Index: {
    screen: Index,
    navigationOptions: {
      title: "Index",
      header: false,
    },
  },
  Signup: {
    screen: Signup,
    navigationOptions: {
      title: "Registro",
      header: false,
    },
  },
  Instrucciones: {
    screen: Instructions,
    navigationOptions: {
      title: "Recover",
      header: false,
    },
  },
  RecoverSuccess: {
    screen: RecoverSuccess,
    navigationOptions: {
      title: "Recover",
      header: false,
    },
  },
  Suscription: {
    screen: Suscription,
    navigationOptions: {
      title: "Suscription",
      header: false,
    },
  },
  Cupon: {
    screen: Cupon,
    navigationOptions: {
      title: "Cupon",
      header: false,
    },
  },
  SuscriptionCupon: {
    screen: SuscriptionCupon,
    navigationOptions: {
      title: "SuscriptionCupon",
      header: false,
    },
  },
};

const StartStack = createStackNavigator(StartScreens, {
  defaultNavigationOptions: {
    headerTintColor: "white",
    headerStyle: { backgroundColor: "red", height: 0 },
  },
});

class MyCustomHeaderBackImage extends React.Component<any, any> {
  render() {
    return (
      <Svg
        width="50"
        height="40"
        viewBox="0 0 50 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M50 0L50 40L-1.27162e-06 40L4.76837e-07 -2.18557e-06L50 0Z"
          fill="#ED1B24"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.4142 19L30.707 14.7072L29.2928 13.293L22.5857 20.0001L29.2928 26.7072L30.707 25.293L26.414 21H35V19H26.4142Z"
          fill="white"
        />
      </Svg>
    );
  }
}

function MyCustomHeaderRank({ navigation }) {
  const openRanking = () => {
    navigation.navigate("Ranking");
  };
  return (
    <Svg
      width="50"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onPress={openRanking}
    >
      <Path
        d="M26.8 10H20.8L20 14C22.1 14 24.1 14.7 25.6 16L26.8 10ZM20 16C16.1 16 13 19.1 13 23C13 26.9 16.1 30 20 30C23.9 30 27 26.9 27 23C27 19.1 23.9 16 20 16ZM22.8 27.4L20 25.3L17.2 27.4L18.3 24.1L15.4 22H18.9L20 18.7L21.1 22H24.6L21.8 24.1L22.8 27.4ZM20 14L19.2 10H13.2L14.4 16C15.9 14.7 17.9 14 20 14Z"
        fill="white"
      />
    </Svg>
  );
}

function MyCustomDrawerImage({ props }) {
  return (
    <Svg
      width="59"
      height="16"
      viewBox="0 0 59 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles.sidebarLogo}
      onPress={() => { props.navigation.navigate("IndexEspecialidades") }}
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.50246 3.1283L0 15.6195L12.6388 15.5984L11.3739 12.531L4.99649 12.5415L8.30639 4.82542C8.61209 4.19296 9.29726 4.41432 9.40267 4.90975L14.0829 15.5879H17.7091L12.1223 2.98073C11.3739 1.09387 6.88334 0.640602 5.50246 3.1283ZM54.8981 4.55135C53.6542 0.292746 49.48 -0.497837 47.7196 4.15079L46.149 9.86407L44.6205 4.4354C44.0513 2.39043 42.5123 1.31523 40.91 1.34686C39.4765 1.36794 37.9058 2.22177 37.1469 4.31945L33.8475 15.5668H37.4947L40.2354 6.18523C40.5622 4.70947 41.5847 4.82542 41.9115 6.39605L44.3781 15.5563H48.0042V15.5141L50.5341 6.20631C50.7976 5.03625 51.8939 4.37216 52.3577 6.25901L54.8981 15.5563H58.1237L54.8981 4.55135ZM27.2066 9.95894C30.7063 9.91678 32.8777 8.44102 32.825 5.83737C32.7618 2.97019 30.6325 1.56822 28.577 1.46281L18.7526 1.39956V15.609H22.1469V10.8128H22.4104L32.9621 15.999V12.4572L27.2066 9.95894ZM22.0942 7.2288V4.72001L28.0499 4.70947C29.7681 5.01516 29.9684 7.0496 28.0499 7.21826L22.0942 7.2288Z"
        fill="white"
      />
    </Svg>
  );
}

class MyCustomDrawerImageHidden extends React.Component<any, any> {
  render() {
    return (
      null
    );
  }
}

function MyCustomMenuImage({ navigation }) {
  const openMenu = () => {
    navigation.openDrawer();
  };

  return (
    <TouchableHighlight
      onPress={openMenu}
    >
    <Svg
      onTouchStart={() => { alert('test action') }}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M50 0L50 40L-1.27162e-06 40L4.76837e-07 -2.18557e-06L50 0Z"
        fill="transparent"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35 21H24V19H35V21Z"
        fill="#666666"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 14H39V16H19V14ZM19 19H39V21H19V19ZM39 24H19V26H39V24Z"
        fill="white"
      />
    </Svg>
  </TouchableHighlight>
  
  );
}

const AlumnoScreens = {
  IndexEspecialidades: {
    screen: IndexEspecialidades,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Especialidades",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
 
  Stages: {
    screen: Stages,
    navigationOptions: ({ navigation }) => ({
      title: `Test de ${navigation.state.params.especialidad.name}`,
    }),
  },
  Casos: {
    screen: Casos,
    navigationOptions: ({ navigation }) => ({
      title: `Caso`,
    }),
  },
};

const AlumnoStack = createStackNavigator(AlumnoScreens, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#ED1B24",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "cerebri-sans-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const InglesScreens = {
  IndexIngles: {
    screen: IndexIngles,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Ingles",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  Stages: {
    screen: Stages,
    navigationOptions: ({ navigation }) => ({
      title: `Test de ${navigation.state.params.especialidad.name}`,
    }),
  },
  Casos: {
    screen: Casos,
    navigationOptions: ({ navigation }) => ({
      title: `Caso`,
    }),
  },
};

const InglesStack = createStackNavigator(InglesScreens, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#ED1B24",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "cerebri-sans-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const AleatorioScreens = {
  AleatorioIndex: {
    screen: IndexAleatorio,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Aleatorio",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  Stages: {
    screen: Stages,
    navigationOptions: ({ navigation }) => ({
      title: `Test de ${navigation.state.params.especialidad.name}`,
    }),
  },
  Casos: {
    screen: Casos,
    navigationOptions: ({ navigation }) => ({
      title: `Caso`,
    }),
  },
};

const AleatorioStack = createStackNavigator(AleatorioScreens, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#ED1B24",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "cerebri-sans-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const ImagenesScreens = {
  ImagenesIndex: {
    screen: IndexImagenes,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Imagenes",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  Stages: {
    screen: Stages,
    navigationOptions: ({ navigation }) => ({
      title: `Test de ${navigation.state.params.especialidad.name}`,
    }),
  },
  Casos: {
    screen: Casos,
    navigationOptions: ({ navigation }) => ({
      title: `Caso`,
    }),
  },
};

const ImagenesStack = createStackNavigator(ImagenesScreens, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#ED1B24",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "cerebri-sans-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const ProfileScreen = {
  Profile: {
    screen: Profile,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Mi Perfil",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,
      };
    },
  },
  PasswordEdit: {
    screen: PasswordEdit,
    navigationOptions: {
      title: "Editar Contraseña",
    },
  },
};

const ProfileStack = createStackNavigator(ProfileScreen, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#ED1B24",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "cerebri-sans-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const ResultadosScreen = {
  Resultados: {
    screen: Resultados,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Resultados",
        headerLeft: <MyCustomMenuImage navigation={navigation} />
      };
    },
  },
};

const ResultadosStack = createStackNavigator(ResultadosScreen, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#111",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "cerebri-sans-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " "
  },
});

const RankingScreen = {
  Ranking: {
    screen: Ranking,
    navigationOptions: ({ navigation }) => {
      return {
        title: "Ranking",
        headerLeft: <MyCustomMenuImage navigation={navigation} />,

      };
    },
  },
};

const RankingStack = createStackNavigator(RankingScreen, {
  defaultNavigationOptions: {
    headerTintColor: "#fff",
    headerStyle: {
      backgroundColor: "#111",
      height: 100,
      shadowColor: "transparent",
    },
    headerTitleStyle: {
      fontFamily: "cerebri-sans-semibold",
    },
    headerBackImage: <MyCustomHeaderBackImage />,
    headerBackTitle: " ",
  },
});

const CustomDrawerComponent = (props) => (
  <SafeAreaView style={{ flex: 1 }}>
    <ScrollView style={styles.sidebar}>
      <DrawerItems {...props} />
    </ScrollView>
  </SafeAreaView>
);

const RootDrawerNavigator = createDrawerNavigator(
  {
    Start: {
      screen: StartStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerImageHidden />,
        drawerLockMode: "locked-closed",
        disabled: true
      },
    },
    IndexAleatorio: {
      screen: AleatorioStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerImageHidden />,
      },
    },
    IndexIngles: {
      screen: InglesStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerImageHidden />,
      },
    },
    IndexImagenes: {
      screen: ImagenesStack,
      navigationOptions: {
        drawerLabel: <MyCustomDrawerImageHidden />,
      },
    },
    IndexEspecialidades: {
      screen: AlumnoStack,
      navigationOptions: {
        //drawerLabel: () => drawerLabel("Especialidades"),
        drawerLabel: <MyCustomDrawerImageHidden />,
      },
    },
    Profile: {
      screen: ProfileStack,
      navigationOptions: {
        drawerLabel: () => drawerLabel("Cuenta"),
      },
    },
    Ranking: {
      screen: RankingStack,
      navigationOptions: {
        drawerLabel: () => drawerLabel("Ranking"),
      },
    },
    Resultados: {
      screen: ResultadosStack,
      navigationOptions: {
        drawerLabel: () => drawerLabel("Resultados"),
      },
    },
  },
  {
    contentComponent: (props) => (
      <ScrollView style={{ flex: 1 }}>
        <SafeAreaView forceInset={{ top: "always", horizontal: "never" }}>
          <MyCustomDrawerImage props={props} />
          {/*<ExamsDropdown props={props} />*/}
          <List.Section>
            <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Especialidades" onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  props.navigation.navigate("IndexEspecialidades");
                }, 200);
              }} />
              <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Aleatorio" onPress={() => {
                    props.navigation.closeDrawer();
                    setTimeout(() => {
                      props.navigation.navigate("IndexAleatorio");
                    }, 200);
                }} />
              <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Ingles" onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  props.navigation.navigate("IndexIngles");
                }, 200);
              }} />
              <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Imagenes" onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  props.navigation.navigate("IndexImagenes");
                }, 200);
              }} />
          </List.Section>
          <DrawerNavigatorItems {...props} />
          
          {Platform.OS !== 'web' && (
            <View style={styles.sidebarItemLogout}>
            <Text
              style={styles.sidebarItemText}
              onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  props.navigation.navigate("Index");
                }, 200);
              }}
            >
              Tutorial
            </Text>
          </View>
          )}
          <View style={styles.sidebarItemLogout}>
            <Text
              style={styles.sidebarItemText}
              onPress={() => {
                props.navigation.closeDrawer();
                setTimeout(() => {
                  if (Platform.OS === 'web') {
                    localStorage.clear();
                    window.location.replace('https://registro.armapp.mx/login');
                  } else {
                    props.navigation.navigate("Login");
                  }
                }, 200);
              }}
            >
              Cerrar sesión
      </Text>
          </View>
        </SafeAreaView>
      </ScrollView>
    ),
    drawerWidth: (Dimensions.get("window").width * 3) / 4,
    contentOptions: {
      activeTintColor: "#D6161E",
    },
    drawerBackgroundColor: "#ED1B24",
  }
);

const ExamsDropdown = ({ props }) => {
  const [expanded, setExpanded] = React.useState(true);

  const handlePress = () => setExpanded(!expanded);

  return (
    <List.Section>
      
        
        <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Imagenes" onPress={() => {
          props.navigation.closeDrawer();
          setTimeout(() => {
            props.navigation.navigate("IndexImagenes");
          }, 200);
        }} />
        <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Ingles" onPress={() => {
          props.navigation.closeDrawer();
          setTimeout(() => {
            props.navigation.navigate("IndexIngles");
          }, 200);
        }} />
        <List.Item style={styles.sidebarItemAccordionNest} titleStyle={styles.sidebarItemText} title="Aleatorio" onPress={() => {
          props.navigation.closeDrawer();
          setTimeout(() => {
            props.navigation.navigate("IndexAleatorio");
          }, 200);
        }} />
        

    </List.Section>
  );
};

function drawerLabel(label) {
  return (
    <View style={styles.sidebarItem}>
      <Text style={styles.sidebarItemText}> {label} </Text>
    </View>
  );
}

const theme = {
  roundness: 2,
  colors: {
    primary: '#fff',
    text: '#fff',
    accent: '#fff',
  },
};

const styles = StyleSheet.create({
  sidebar: {
    padding: 40,
  },
  sidebarLogo: {
    marginBottom: 50,
    marginLeft: 40,
    marginTop: 50,
  },
  sidebarItem: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
  },
  sidebarItemLogout: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 45,
    color: "#fff",
  },
  sidebarItemAccordion: {
    paddingLeft: 35,
    marginBottom: -10
  },
  sidebarItemAccordionNest: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 35,
    fontSize: 14,
    marginBottom: -10
  },
  sidebarItemAccordionTitle: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "500"
  },
  sidebarItemText: {
    color: "#fff",
    fontSize: 14,
  },
  sidebarItemTextBold: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "500"
  },
  icon: {
    position: "absolute",
    left: -120,
    color: "#666",
  },
});

const NavigatorArm = createAppContainer(RootDrawerNavigator);
export default NavigatorArm;
