import React, { Component } from "react";

import * as Font from "expo-font";
import NavigatorArm from "./components/AppNavigator";
import { StatusBar } from "react-native";
import OfflineNotice from './components/Navigation/OfflineNotice'


/*

const AppNavigator = createDrawerNavigator
(
  {
    Start: { screen: Start },
    Login: { screen: Login },
    Index: { screen: Index },
    Signup: { screen: Signup },
    Instrucciones: { screen: Instructions },
    IndexEspecialidades: { screen: IndexEspecialidades },
    Stages: { screen: Stages },
    Casos: { screen: Casos },
    Suscription: { screen: Suscription },
    Cupon: { screen: Cupon },
    SuscriptionCupon: { screen : SuscriptionCupon }
  }
);

const Container = createAppContainer(AppNavigator);
*/

class App extends Component {
 componentDidMount() {
  Font.loadAsync({
   "cerebri-sans-semibold": require("./assets/fonts/CerebriSans-SemiBold.ttf"),
  });

  Font.loadAsync({
   "cerebri-sans-regular": require("./assets/fonts/CerebriSans-Regular.ttf"),
  });
 }
 render() {
  return (
    <>
   <NavigatorArm>
    <StatusBar barStyle="light-content" backgroundColor="#ED1B24" />
   </NavigatorArm>
   <OfflineNotice/>
   </>
  );
 }
}

export default App;
