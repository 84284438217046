import React, { Component } from "react";
import { Dimensions } from "react-native";
import {
 Image,
 Text,
 TextInput,
 View,
 StyleSheet,
 SafeAreaView,
 TouchableOpacity,
 Alert,
 StatusBar,
} from "react-native";
import { Button, Divider } from "react-native-paper";
import {  postSuscribe, getTempOrder } from "../ArmFunctions";
import { login } from "../ArmFunctions";
import { AsyncStorage } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";
import * as Font from "expo-font";
import { Avatar, List, RadioButton, DefaultTheme } from "react-native-paper";

class PendingPayment extends Component {
 state = {
  value: "first",
  email: "",
  token: "",
  order: null,
 };

 componentDidMount() {
  Font.loadAsync({
   "cerebri-sans-semibold": require("../../assets/fonts/CerebriSans-SemiBold.ttf"),
  });

  Font.loadAsync({
   "cerebri-sans-regular": require("../../assets/fonts/CerebriSans-Regular.ttf"),
  });
  AsyncStorage.getItem("user_token", (error, result) => {
   this.setState({
    token: result,
   });
   getTempOrder(result).then((res) => {
    this.setState({
     order: res,
    });
   });
  });
 }



 render() {
  var heightScreen = Dimensions.get("window").height;
  var date = this.state.order ? this.state.order.created_at : null
  
  return (
   <>
    <StatusBar barStyle="light-content" backgroundColor="#ED1B24" />
    {heightScreen > 780 ? (
     <View style={styles.topBarExtra}></View>
    ) : (
     <View style={styles.topBarExtraMin}></View>
    )}
    <View style={styles.topBar}>
     <TouchableOpacity
      style={styles.backButton}
      onPress={() => this.props.navigation.navigate("Start")}
     >
      <Image
       style={styles.backButtonIcon}
       source={require("../../assets/back.png")}
      />
     </TouchableOpacity>
     <Text style={styles.headerTitle}>Realiza tu pago</Text>
     <TouchableOpacity style={styles.backButton}>
      <Image
       style={styles.backButtonIcon}
       source={require("../../assets/backno.png")}
      />
     </TouchableOpacity>
    </View>
    <View style={styles.container}>
     <Text style={styles.textLarge}>
      Presenta este comprobante en cualquier tienda OXXO del país para realizar
      el pago de tu compra.
     </Text>
     <View>
      <Divider />
      <View style={styles.row}>
       <Text style={styles.rowSubtitle}>Referencia</Text>
        <Text style={styles.rowTitle}>{this.state.order ? this.state.order.reference : '---'}</Text>
      </View>
      <Divider />
      <View style={styles.row}>
       <Text style={styles.rowSubtitle}>Expiración*</Text>
       <Text style={styles.rowTitle}>{this.state.order ? this.state.order.expiration_date : '---'}</Text>
      </View>
      <Divider />
      <View style={styles.row}>
       <Text style={styles.rowSubtitle}>Email</Text>
       <Text style={styles.rowTitle}>{this.state.order ? this.state.order.email: '---'}</Text>
      </View>
      <Divider />
      <View style={styles.row}>
       <Text style={styles.rowSubtitle}>Monto a Pagar**</Text>
        <Text style={styles.rowTitleBold}>{this.state.order ? `$ ${this.state.order.total}` : '$ ---'}</Text>
      </View>
      <Divider />
      <View style={styles.imageView}>
       <Image
        style={styles.oxxoImage}
        source={require("../../assets/oxxo-conekta.png")}
       />
      </View>
     </View>

     <Text style={styles.text}>
      Indica al cajero de la tienda el valor exacto que figura en el presente
      comprobante. *Te recordamos que una vez realizado el pago en el OXXO, el
      pago se hará efectivo 24 horas después y serás notificado vía correo
      electrónico. **OXXO cobrará una comisión adicional al momento de realizar
      el pago.
     </Text>
    </View>
   </>
  );
 }
}

const styles = StyleSheet.create({
 container: {
  flex: 1,
  backgroundColor: "white",
  padding: 20,
  paddingTop: 20,
 },
 row: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: 20,
  marginBottom: 20,
 },
 rowSubtitle: {
  color: "#666666",
  fontFamily: "cerebri-sans-regular",
  fontSize: 12,
 },
 rowTitle: {
  color: "#222",
  fontFamily: "cerebri-sans-regular",
  fontSize: 14,
 },
 rowTitleBold: {
  color: "#222",
  fontFamily: "cerebri-sans-semibold",
  fontSize: 14,
 },
 textLarge: {
  marginTop: 15,
  marginBottom: 20,
  fontFamily: "cerebri-sans-regular",
  fontSize: 14,
 },
 text: {
  marginTop: 15,
  marginBottom: 15,
  fontFamily: "cerebri-sans-regular",
  fontSize: 13,
 },
 inputText: {
  backgroundColor: "white",
  borderBottomColor: "#E2E2E2",
  borderBottomWidth: 1,
  height: 50,
  color: "#000",
  paddingLeft: 0,
 },
 imageView: {
  alignItems: "center",
  marginTop: "auto",
 },
 buttonMain: {
  backgroundColor: "#FDCD25",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#fff",
  display: "flex",
  fontWeight: "500",
  fontSize: 13,
  height: 46,
  justifyContent: "center",
  marginBottom: 30,
  marginTop: 30,
  width: "100%",
 },
 buttonText: {
  textAlign: "center",
  color: "black",
  fontWeight: "700",
 },
 headerTitle: {
  fontSize: 18,
  fontWeight: "500",
  color: "#fff",
  fontFamily: "cerebri-sans-semibold",
 },
 backButtonIcon: {
  height: 20,
  width: 20,
 },
 topBar: {
  alignItems: "center",
  backgroundColor: "#ED1B24",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: 45,
  paddingLeft: 20,
  paddingRight: 20,
 },
 topBarExtra: {
  backgroundColor: "#ED1B24",
  height: 45,
 },
 topBarExtraMin: {
  backgroundColor: "#ED1B24",
  height: 25,
 },
 startButtonText: {
  color: "#ED1B24",
  fontSize: 13,
  fontWeight: "600",
  textAlign: "right",
 },
 especialidadesList: {
  marginTop: 15,
 },
 especialidadesListSpec: {
  backgroundColor: "#F8BC47",
 },
 planesListPart: {
  fontFamily: "cerebri-sans-semibold",
  display: "flex",
  alignItems: "center",
  top: 8,
  right: 4,
  color: "#222",
  backgroundColor: "#fff",
  borderColor: "#fff",
  borderWidth: 1,
  position: "absolute",
 },
 planesListItem: {
  borderColor: "#E2E2E2",
  borderRadius: 5,
  borderWidth: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: 82,
  paddingBottom: 10,
  marginBottom: 20,
 },
 buttonAlt: {
  backgroundColor: "#fff",
  borderColor: "transparent",
  borderRadius: 5,
  color: "#000",
  display: "flex",
  fontWeight: "500",
  fontSize: 13,
  height: 48,
  justifyContent: "center",
  marginTop: 25,
  width: "100%",
 },
 buttonText: {
  textAlign: "center",
  color: "#000",
  fontWeight: "700",
 },
 buttonTextAlt: {
  textAlign: "center",
  textDecorationLine: "underline",
  color: "#000",
  fontWeight: "700",
 },
 oxxoImage: {
  margin: "auto",
  height: 30,
  width: 122,
  marginTop: 10,
  marginBottom: 5,
 },
});

export default PendingPayment;
